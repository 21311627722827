<template>
    <div class="licenseAgreement">
        <div>
            <div class="top-title">签署权限授权</div>
            <div class="content">
                <div class="title">申请信息</div>
                <div class="info-item">
                    <span>申请人姓名:</span>
                    <span>{{detailFrom.signerName}}</span>
                </div>
                <div class="info-item">
                    <span>申请人手机号:</span>
                    <span>{{detailFrom.signerMobile}}</span>
                </div>
                <div class="info-item">
                    <span>合同名称:</span>
                    <span>{{detailFrom.contractName}}</span>
                </div>
                <div class="info-item">
                    <span>合同签署方:</span>
                    <span v-for="(item,index) in detailFrom.signParty" :key="index">
                        {{item.signerName}}&nbsp;&nbsp;
                    </span>
                </div>
                <div class="title">授权设置</div>
                <div class="info-item1">
                    <span>签署企业合同:</span>
                    <van-radio-group v-model="formObj.authorityType">
                        <van-radio name="1" icon-size="15px">当前合同</van-radio>
                        <van-radio name="2" icon-size="15px">当前发件方发送的合同</van-radio>
                        <van-radio name="3" icon-size="15px">全部发件方发送的合同</van-radio>
                    </van-radio-group>
                </div>
                <div class="info-item1" style="margin-top:20px;">
                    <span>授权有效期:</span>
                    <van-radio-group v-model="dateSelect" @change="deteChange">
                        <van-radio name="1" icon-size="15px">永久有效</van-radio>
                        <van-radio name="2" icon-size="15px">固定有效期</van-radio>
                    </van-radio-group>
                </div>
                <div class="time" v-show="dateSelect == 2">
                    <div @click="openPopup">{{txtTime}}</div>
                </div>
                <van-popup v-model="show" position="bottom" :style="{ height: '30%' }">
                    <van-datetime-picker v-model="currentDate" type="date" title="选择年月日" :min-date="minDate"
                        :max-date="maxDate" @cancel="cancelTime" @confirm="confirmTime" :formatter="formatter" />
                </van-popup>
            </div>
        </div>
        <div class="footer-btn">
            <span @click="delegating1(2)">拒绝授权</span>
            <span @click="delegating(1)">授权</span>
        </div>
        <van-popup v-model="show1" position="bottom" >
            <div class="reson-box">
                <div class="reson-box-title">拒绝授权原因</div>
                <textarea class="reson-box-textarea" name="description" v-model="formObj.reason" placeholder="请输入拒绝授权原因" style="resize:none;"></textarea>
                <div class="reson-box-btn" @click="confimBtn">确定</div>
            </div>
        </van-popup>
    </div>
</template>
<script>
var myDate = new Date()
var year = myDate.getFullYear() 
var month = myDate.getMonth()
var day = myDate.getDate()
import { mapState } from 'vuex'
import {grantAuthorization, getApplyDetail} from '@/util/api'
export default {
    data() {
        return {
            show: false,
            show1: false,
            minDate: new Date(year, month, day),
            maxDate: new Date(2100, 7, 1),
            currentDate: new Date(),
            formObj:{
                applyId: '', //申请ID
                approvalResult: '', //授权结果 1通过 2不通过
                authorityType: '1', // 1=当前合同权限 2=当前发件方权限 3=全部合同权限
                endTime: '', // 永久有效则不需传值
                reason: '', // 选填拒绝授权原因
            },
            detailFrom: {},
            dateSelect: '1',
            txtTime: '选择时间',
        }
    },
    computed: {
        ...mapState(['linkCodeInfo'])
    },
    created () {
        const {linkCode} = this.$route.query
        getApplyDetail({linkCode: linkCode}).then(res =>{
            console.log(res);
            this.detailFrom = {...res}
            this.formObj.applyId= res.applyId
        })
    },
    methods: {
        formatter(type, val) {
            if (type === 'year') {
                return `${val}年`
            } else if (type === 'month') {
                return `${val}月`;
            } else if (type === 'day') {
                return `${val}日`;
            }
            return val;
        },

        openPopup() {
            this.show = true
        },
        confirmTime() {
            this.show = false
            this.formObj.endTime = this.changeTimeFormat(this.currentDate)
            this.txtTime = this.changeTimeFormat(this.currentDate)
        },
        cancelTime() {
            this.show = false
        },
        deteChange () {
            this.formObj.endTime = ''
            this.txtTime = '选择日期与时间'
        },
        delegating (val) {
            this.formObj.approvalResult = val
            if(this.dateSelect == 2 && this.formObj.endTime == '') {
                this.$toast.fail('授权截至日期不能为空')
                return
            }
            if(this.dateSelect == 2){
                this.formObj.endTime = this.formObj.endTime + ' '+ '23:59:59'
            }
            grantAuthorization(this.formObj).then(res =>{
                console.log(res);
                this.$router.replace({path:'/authenticationPage',query:{flag:3}})
            })
        },
        delegating1 (val) {
            this.formObj.approvalResult = val
            this.show1 = true
        },
        cancelBtn () {
            this.show1 = false
        },
        confimBtn () {
            this.formObj.endTime = ''
            if(this.formObj.reason == ''){
                this.$toast.fail('拒绝原因不能为空')
                return
            }
            grantAuthorization(this.formObj).then(res =>{
                console.log(res);
                this.$router.replace({path:'/authenticationPage',query:{flag:4}})
                this.show1 = false
            })
        },
        changeTimeFormat (str) {
            if ((str + '').indexOf('-') != -1) {
            str = str.replace(new RegExp(/-/gm), '/')
            }
            let d = new Date(str)
            let newDateYear = d.getFullYear()
            let newDateMonth = (d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)
            let newDateDay = d.getDate() + '' < 10 ? '0' + d.getDate() + '' : d.getDate() + ''
            return newDateYear + '-' + newDateMonth + '-' + newDateDay
        },
    }
}
</script>
<style lang="less" scoped>
.licenseAgreement {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top-title {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        color: #fff;
        justify-content: center;
        font-size: 17px;
        background: #0067E1;

    }

    .content {
        width: 100%;
        flex: 1;
        background: #fff;
        padding: 14px;
        box-sizing: border-box;

        .title {
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 17px;
            color: #30586C;
            font-weight: bold;
        }

        .info-item {
            width: 100%;
            display: flex;
            line-height: 30px;
            font-size: 15px;
            span {
                &:nth-of-type(1) {
                    min-width: 100px;
                    text-align: right;
                    color: #757B85;
                    margin-right: 10px;
                }
            }
        }

        .info-item1 {
            display: flex;
            width: 100%;
            font-size: 15px;

            span {
                display: inline-block;
                min-width: 100px;
                text-align: right;
                color: #757B85;
                margin-right: 10px;
            }

            .van-radio {
                margin-bottom: 10px;
            }
        }

        .time {
            width: 100%;
            height: 48px;
            display: flex;
            justify-content: center;

            div {
                width: 300px;
                height: 100%;
                line-height: 48px;
                padding-left: 20px;
                color: #C5C5C5;
                background: #F6F7F8;
                font-size: 17px;
            }
        }
    }
    .footer-btn {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        box-sizing: border-box;
        margin-bottom: 20px;

        span {
            width: 155px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            font-size: 16px;
            border-radius: 25px;

            &:nth-of-type(1) {
                color: #666666;
                border: 1px solid #999999;
            }

            &:nth-of-type(2) {
                color: #fff;
                background: linear-gradient(90deg, #0067E1, #77A3FF);
            }
        }
    }
}
.reson-box {
    width: 100%;
    // height: 260px;
    padding: 20px;
    box-sizing: border-box;
    &-title {
        height: 23px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 23px;
        color: #30586C;
        opacity: 1;
        margin-bottom: 16px;
    }
    &-textarea {
        width: 100%;
        height: 138px;
        border: 0;
        background: #F6F7F8;

        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 20px;
        color: #000;
        opacity: 1;

        padding: 12px;
        box-sizing: border-box;
        &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #9c9c9c;

        }
    }
    &-btn {
        width: 100%;
        height: 44px;
        background: #0067E1;

        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 44px;
        color: #FFFFFF;
        opacity: 1;
        border-radius: 30px;
        text-align: center;
        margin-top: 20px;
    }
}
</style>