import { render, staticRenderFns } from "./licenseAgreement.vue?vue&type=template&id=d1fe9e90&scoped=true&"
import script from "./licenseAgreement.vue?vue&type=script&lang=js&"
export * from "./licenseAgreement.vue?vue&type=script&lang=js&"
import style0 from "./licenseAgreement.vue?vue&type=style&index=0&id=d1fe9e90&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1fe9e90",
  null
  
)

export default component.exports